import React from "react"
import { graphql } from "gatsby"
import Fade from "react-reveal/Fade"
import PageTransition from "gatsby-plugin-page-transitions"
import {
  Article,
  Header,
  HeaderTitle,
  ImageWrapper,
  ImagePrimary,
  Image,
  SecondHeader,
  Content,
  Main,
  Sidebar,
  SidebarContent,
  EventDate,
  Event,
  Intro,
  Line,
  Info,
  InfoDiv,
  Space,
} from "../sections/project.styles"

import SEO from "../components/seo"
import Video from "../components/Video/Video"

const Project = props => {
  const {
    content,
    cooperation,
    description,
    direction,
    event,
    images,
    premiere,
    primaryImage,
    there,
    title,
    keywords,
    video,
  } = props.data.contentfulProject

  return (
    <>
      <SEO
        title={`${title} | Dzikistyl Company`}
        keywords={keywords}
        description={description}
      />
      <PageTransition>
        <Article>
          <Header>
            <HeaderTitle>{title}</HeaderTitle>
          </Header>
          <Fade left>
            <ImageWrapper>
              <ImagePrimary fluid={primaryImage.fluid} alt={title} />
            </ImageWrapper>
          </Fade>
          <Fade bottom>
            <SecondHeader>
              <Intro>Premiera</Intro>
              <EventDate>{premiere}</EventDate>
              <Intro>Wydarzenie</Intro>
              <Event>{event}</Event>
              <Line />
            </SecondHeader>
          </Fade>
          <Fade bottom>
            <Content>
              <Main>
                <Intro>Opis</Intro>
                <InfoDiv
                  dangerouslySetInnerHTML={{
                    __html: content.childContentfulRichText.html,
                  }}
                />

                {video && (
                  <Video
                    videoSrcURL={video.file.url}
                    videoTitle={video.title}
                  />
                )}
                {images &&
                  images.map(({ fluid }, i) => (
                    <Image key={i} fluid={fluid} alt={title} />
                  ))}
              </Main>
              <Sidebar>
                <SidebarContent>
                  <Intro>Reżyseria</Intro>
                  {direction.map(d => (
                    <Info key={d}>{d}</Info>
                  ))}
                  <Intro>Występują</Intro>
                  {there.map(t => (
                    <Info key={t}>{t}</Info>
                  ))}
                  {cooperation && <Intro>Współpraca</Intro>}
                  {cooperation &&
                    cooperation.map(c => <Info key={c}>{c}</Info>)}
                </SidebarContent>
              </Sidebar>
            </Content>
          </Fade>
          <Space />
        </Article>
      </PageTransition>
    </>
  )
}

export default Project

export const pageQuery = graphql`
  query ProjectBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    contentfulProject(slug: { eq: $slug }) {
      id
      title
      slug
      premiere
      event
      direction
      there
      cooperation
      category
      description
      content {
        childContentfulRichText {
          html
        }
      }
      primaryImage {
        fluid {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      images {
        fluid {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      video {
        file {
          url
          details {
            size
            image {
              width
              height
            }
          }
          fileName
          contentType
        }
        title
        description
      }
    }
  }
`
