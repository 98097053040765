import styled from "styled-components"
import { fonts, device, colors, limiter, paddingTop } from "../utils/styles"
import Img from "gatsby-image"

export const Article = styled.article``

export const Header = styled.header`
  ${limiter};
  ${paddingTop};
`

export const HeaderTitle = styled.h1`
  font-weight: ${fonts.fontBold};
  font-size: 1.45rem;
  line-height: 1.9rem;
  margin-bottom: 0.5em;

  @media ${device.tablet} {
    font-size: 2.6rem;
    line-height: 3.15rem;
  }

  @media ${device.laptop} {
    font-size: 3.6rem;
    line-height: 4.1rem;
    margin-top: 0.5em;
    letter-spacing: 2.5px;
  }
`

export const ImageWrapper = styled.figure`
  overflow: hidden;
`

export const ImagePrimary = styled(Img)`
  max-height: 190px;
  /* transition: filter 0.25s ease-in-out;
  filter: brightness(72.5%);

  &:hover {
    filter: brightness(92.5%);
  } */

  @media ${device.mobileL} {
    max-height: 265px;
  }

  @media ${device.tablet} {
    max-height: 330px;
    max-width: calc(100% - 70px);
  }

  @media ${device.laptop} {
    max-height: 365px;
    max-width: 1340px;
  }
`

export const Image = styled(Img)`
  margin-top: 1.5em;

  @media ${device.tablet} {
    margin-top: 3em;
  }
`

export const SecondHeader = styled.div`
  ${limiter};
`

export const Content = styled.section`
  ${limiter};

  display: flex;
  flex-direction: column-reverse;

  @media ${device.tablet} {
    flex-direction: row;
  }
`

export const Main = styled.article`
  margin-top: 1em;
  position: relative;

  @media ${device.tablet} {
    flex: 0.72;
    margin-top: 0;
    padding-right: 2.2em;
    padding-top: 3.4em;
  }

  @media ${device.laptop} {
    padding-right: 3em;
  }
`

export const Sidebar = styled.aside`
  @media ${device.tablet} {
    flex: 0.28;
  }
`

export const SidebarContent = styled.div`
  @media ${device.tablet} {
    padding-top: 3.4em;
    position: sticky;
    top: 0;
  }
`

export const EventDate = styled.p`
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: ${fonts.fontBold};

  @media ${device.tablet} {
    font-size: 1.3rem;
    line-height: 1.7rem;
  }

  @media ${device.laptop} {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
`

export const Event = styled.h2`
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: ${fonts.fontBold};

  @media ${device.tablet} {
    font-size: 1.3rem;
    line-height: 1.7rem;
  }

  @media ${device.laptop} {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
`

export const Intro = styled.p`
  font-size: 0.67rem;
  text-transform: uppercase;
  margin-top: 1.6em;
  margin-bottom: 0.3em;
  font-weight: ${fonts.fontRegular};
  color: ${colors.gray};

  @media ${device.tablet} {
    margin-top: 2.3em;
  }
`

export const Line = styled.span`
  display: block;
  width: 63%;
  max-width: 400px;
  height: 1px;
  background: ${colors.gray};
  margin: 1.4em 0;
  margin-bottom: 0;
`

export const Info = styled.p`
  font-size: 1rem;
  line-height: 1.6rem;
  font-weight: ${fonts.fontLight};

  @media ${device.tablet} {
    font-size: 1.05rem;
    line-height: 1.75rem;
  }

  @media ${device.laptop} {
    font-size: 1.15rem;
    line-height: 2.05rem;
  }
`

export const InfoDiv = styled.div`
  font-size: 0.93rem;
  line-height: 1.72rem;
  font-weight: ${fonts.fontLight};

  @media ${device.tablet} {
    font-size: 1.05rem;
    line-height: 1.75rem;
  }

  @media ${device.laptop} {
    font-size: 1.15rem;
    line-height: 2.05rem;
  }
`

export const Space = styled.span`
  display: block;
  width: 100%;
  height: 3em;

  @media ${device.tablet} {
    height: 5em;
  }
`
