import React from "react"
import PropTypes from "prop-types"
import { VideoWrapper, Iframe } from "./Video.styles"

const Video = ({ videoSrcURL, videoTitle, ...props }) => {
  return (
    <VideoWrapper>
      <Iframe
        src={videoSrcURL}
        title={videoTitle}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
      />
    </VideoWrapper>
  )
}

Video.propTypes = {
  videoSrcURL: PropTypes.string.isRequired,
  videoTitle: PropTypes.string.isRequired,
}

export default Video
