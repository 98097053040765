import styled from "styled-components"
import { device } from "../../utils/styles"

export const VideoWrapper = styled.div`
  position: relative;
  overflow: hidden;
  margin-top: 1.5em;
  /* Calculated from the aspect ration of the content (in case of 16:9 it is 9/16= 0.5625) */
  padding-top: 56.25%;

  @media ${device.tablet} {
    margin-top: 3em;
  }
`

export const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`
